import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../components/ComparisonResultHeroUserLocation";
import BankingIllustration from "../../../svg/ComparisonResultPageIllustrations/personalbanking.svg";
import VioSVG from "../../../svg/ComparisonResultGridLogos/personalbanking/viobank.svg";
import CitibankSVG from "../../../svg/ComparisonResultGridLogos/personalbanking/citibank.svg";
import AllySVG from "../../../svg/ComparisonResultGridLogos/personalbanking/ally.svg";
import MarcusSVG from "../../../svg/ComparisonResultGridLogos/personalbanking/marcus.svg";
import PNCSVG from "../../../svg/ComparisonResultGridLogos/personalbanking/pncbank.svg";
export const pageMetaData = {
  pageTitle: "Compare Personal Bank Accounts",
  pageDescription: "Find An Account That Will Pay You More The More You Save",
  pageImagePath: "/personalbankaccounts.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "APY"
      }, {
        index: 2,
        text: "Minimum Opening Deposit"
      }, {
        index: 3,
        text: "Minimum Balance"
      }, {
        index: 4,
        text: "Available Apps"
      }, {
        index: 5,
        text: "Pros"
      }, {
        index: 6,
        text: "Cons"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Vio Bank",
          subText: "",
          imageKey: "vio"
        }
      }, {
        index: 2,
        text: "0.66%",
        disableDollarPrefix: true,
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "$100"
      }, {
        index: 4,
        text: "$0"
      }, {
        index: 5,
        text: "iOS, Android"
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Savings account with an attractive rate.|Low opening minimums."
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "No checking account.|No branch or ATM network.|The bank offers a small range of products.|Customers don't have access to budgeting tools or the ability to make person-to-person payments."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.viobank.com/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Vio Bank",
        subText: "",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Citibank",
          subText: "",
          imageKey: "citi-bank"
        }
      }, {
        index: 2,
        text: "0.90%",
        isPriceCell: true,
        disableDollarPrefix: true,
        showSavingText: false,
        showRating: true,
        rating: 2
      }, {
        index: 3,
        text: "$100"
      }, {
        index: 4,
        text: "$0"
      }, {
        index: 5,
        text: "iOS, Android"
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "If you’re already a Citi customer or you’re looking for an account at a bank that has a large national presence, this high-yield savings account may be worth considering.|The APY is among the top yields you’ll find at a big bank.|The $0 minimum balance required to earn the APY makes it easy for anyone to start saving."
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "The yields offered on deposit products are among the lowest nationally available rates.|Citi’s banking service packages charge monthly fees if accounts fall below minimum balance thresholds.|For all savings accounts, a $100 account minimum opening balance is required."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://online.citi.com/US/login.do",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Citibank",
        subText: "",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Ally Bank",
          subText: "",
          imageKey: "ally"
        }
      }, {
        index: 2,
        text: "0.80%",
        disableDollarPrefix: true,
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "$0"
      }, {
        index: 4,
        text: "$0"
      }, {
        index: 5,
        text: "iOS, Android"
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Ally is a robust online bank with an array of deposit products, loans and services.|The bank offers low-fee deposit accounts with no monthly maintenance fees or minimum balance requirements.|Pays higher yields on CDs and savings accounts than traditional brick-and-mortar banks.|Ally Bank lets customers access more than 43,000 ATMs through the Allpoint network."
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "The Ally Online Savings account doesn’t offer an ATM card. Ally Bank offers zero physical branches.|Reimbursements for out-of-network ATM fees are limited to $10 per statement cycle.|Customers cannot deposit cash."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.ally.com/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Ally Bank",
        subText: "",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Marcus",
          subText: "",
          imageKey: "marcus"
        }
      }, {
        index: 2,
        text: "0.60%",
        isPriceCell: true,
        disableDollarPrefix: true,
        showSavingText: false,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "$0"
      }, {
        index: 4,
        text: "$0"
      }, {
        index: 5,
        text: "iOS, Android"
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "Offers competitive interest rates.|Minimum deposit and balance requirements for CDs and savings accounts are relatively low."
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "No access to branches.|Few products are offered by the bank savings account at this bank.|There is neither a debit card nor an ATM card offered for Marcus savings accounts."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.marcus.com/us/en/savings/osa-mobile?prd=os&chl=ag&schl=agn&cid=3886408&plc=[click_id];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=?click_id=51ee5e69adbe4f1c996c13bd5333cbeeplacement=262454990&dclid=CjgKEAjwkJj6BRDA4aKNxJ-T7AgSJABGqdLcMnuqqxLMdJmWDDLMSy3xn28IukMHyOZF2IJHiPLjIvD_BwE",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Marcus",
        subText: "",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "PNC Bank",
          subText: "",
          imageKey: "pnc"
        }
      }, {
        index: 2,
        text: "1.00%",
        isPriceCell: true,
        showSavingText: false,
        disableDollarPrefix: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "$0"
      }, {
        index: 4,
        text: "$0"
      }, {
        index: 5,
        text: "iOS, Android, Amazon"
      }, {
        index: 6,
        usePopover: true,
        popOverTitle: "Pros",
        popOverContents: "It boasts a competitive APY on its online-only savings account (only available in certain states).|Supports about 2,400 branches.|Provides above-average digital banking features."
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "Cons",
        popOverContents: "At $36, its overdraft fee is higher than average.|Navigating and comparing various bank products online can be difficult.|CDs can be opened only in a physical branch."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.pnc.com/en/personal-banking.html",
        openLinkExternally: false,
        text: "Sign Up",
        title: "Click to sign up to PNC",
        subText: "",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};
const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare personal bank accounts`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<BankingIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="BankingIllustration" />, <BankingIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="BankingIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`Whether you're trying to get your financial health back on track or you're no longer satisfied with your current banking institution, the task of selecting a new personal bank account can be challenging. For one thing, you have dozens of banks and credit unions to choose from, and each of them has its appealing qualities. So, how do you compare personal bank accounts to find the one that works best for you? It involves some homework.`}</p>
      </ComparisonResultPageTextWithImageSection>
      <ComparisonResultHeroUserLocation productCategory="Personal Bank Accounts" mdxType="ComparisonResultHeroUserLocation" />
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <VioSVG className="w-full" imagekey="vio" mdxType="VioSVG" />
  <CitibankSVG className="w-full" imagekey="citi-bank" mdxType="CitibankSVG" />
  <AllySVG className="w-full" imagekey="ally" mdxType="AllySVG" />
  <MarcusSVG className="w-full" imagekey="marcus" mdxType="MarcusSVG" />
  <PNCSVG className="w-full" imagekey="pnc" mdxType="PNCSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="financial" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`Comparing Personal Bank Accounts: Tips and Tricks`}</h2>
        <h3>{`1. See if There Is a Checking Account Fee`}</h3>
        <p>{`Right off the top, one of the first details you need to look at is any fee associated with the checking account. A checking account fee is a monthly administrative fee that the bank takes out of your account to pay themselves back for running all of your transactions and statements. Checking account fees vary widely across banking institutions, with some being as little as $5 and others being upward of $12 for monthly maintenance.`}</p>
        <p>{`Sometimes, you can avoid the monthly fee by meeting other requirements, such as paying bills from the account or depositing a certain amount of money in each billing cycle.`}</p>
        <h4>{`Why Do Some Checking Accounts Have Fees?`}</h4>
        <p>
  Not all checking accounts have monthly fees. While this is more common in credit unions, plenty of other banking institutions charge $0 for monthly fees. For the accounts that do incur fees, there
  are a couple of reasons for this. For example, Vio Bank charges a $5 monthly fee if you use paper statements but $0 if you opt for online statements.
        </p>
        <p>{`Another reason for having fees might have to do with the type of checking account you have and your basic credit check. Most banking institutions have a few levels to choose from for their bank accounts, with some checking accounts having more features, which require a monthly maintenance fee. Likewise, if you have poor financial health, such as recovering from bankruptcy, you might end up paying a monthly fee.`}</p>
        <h4>{`What About Money to Open the Account?`}</h4>
        <p>{`You also want to be on the look-out for any fees associated with opening the account. There is a broad range for how much is required to open a checking account, and sometimes there are accounts that require money to open but do not require monthly fees.`}</p>
        <h3>{`2. Check if the Bank Requires a Minimum Balance`}</h3>
        <p>{`Some banking institutions require your account to have a minimum balance at all times. There are a few reasons for this: protecting the bank from overdrafts and guaranteeing that the customer can pay the bank's monthly fee. If the bank you like the most happens to require a minimum balance, you need to ensure that you can keep that within your budget - or face penalty fees for not adhering to the rules.`}</p>
        <p>{`The minimum balances range from as little as $0 and some as much as $1500 or more. Minimum balances are a double-edged sword; while having the minimum balance might waive your monthly maintenance fee, the minimum balance must also stay in the checking account. Some banks have programs where minimum balances can be waived if you set up automatic deposits into a savings account.`}</p>
        <h3>{`3. Learn About Monthly Transaction Allowances`}</h3>
        <p>{`For a personal bank account, you probably won't have to think about monthly transaction allowances or the number of transactions you can make each month without incurring fees. Many banking institutions offer unlimited free monthly transactions, while others put a cap on free transactions after about 100 to 200 transactions.`}</p>
        <h4>{`When Do Transaction Fees Kick In?`}</h4>
        <p>{`If you happen to have a checking account that has transaction fees, it's best to know how they work and when they kick in. Any transaction over your monthly free allowance will incur a small fee for the bank to complete the transaction. These fees can be as little as $.25 and as much as $.50. Transaction fees may also apply to international purchases, up to 3% per transaction.`}</p>
        <h3>{`4. Understand How Cash Deposits Work`}</h3>
        <p>
  Cash deposits refer to how you put cash directly into your account, either at a banking branch or an ATM associated with your bank. Many banks have daily, or monthly cash deposit limits before the
  deposits incur fees for processing. For example, at PNC, there are fees for any deposit greater than $5000.
        </p>
        <h4>{`ATM Deposit vs Mobile Check Deposit`}</h4>
        <p>{`An ATM cash deposit is different from a mobile check deposit. Mobile check deposits are processed through online banking and work by taking a photo of your check at your convenience. An ATM is not required as this process is done fully online. Most banking institutions offer mobile check deposits for free, which might be preferable to cash deposits that have fees.`}</p>
        <h3>{`5. Double-Check if the Bank Offers Loans`}</h3>
        <p>{`If you're planning any significant life changes, such as paying off credit card debt or purchasing a home, you should know if your personal bank account offers any loans. Most banks and credit unions do offer loans, particularly home loans. The best banking institutions will offer these loans at lower APR, especially if your account is in good standing and you have an excellent credit score.`}</p>
        <h3>{`6. Consider if You Need a Credit Card`}</h3>
        <p>{`A debit card can do just about anything a credit card can do these days since most debit cards are registered through VISA or MasterCard. However, a credit card might still be useful for you to have as an additional resource. You, therefore, want to be sure that your prospective banking institution also offers the possibility of a credit line.`}</p>
        <h4>{`Advantages of Using a Personal Bank Credit Card`}</h4>
        <p>{`Credit cards associating with your personal bank can be immensely useful and convenient. For example, if you get a credit card through your bank, you can usually look forward to lower interest rates. It's also more convenient to pay your credit card bill each month since you can simply transfer the money directly from your checking account.`}</p>
        <h4>{`Disadvantages of Using a Personal Bank Credit Card`}</h4>
        <p>{`However, it's important to understand that your credit card is linked very closely to your checking account - and your overall financial health. For example, suppose you need to file bankruptcy. In that case, the credit card associated with your personal account will be included in the filing, and that may mean that your checking account will also be closed as a penalty. Some banks may also choose to guarantee monthly credit card payments by drafting directly from your checking account.`}</p>
        <p>{`Comparing options for your personal bank account involves a thorough investigation of the rules and fees associated with the account. That means you need to choose your potential account based on what is realistic for your budget and spending habits. The best personal bank accounts do not charge monthly maintenance fees, have no minimum balance or money to open the account, and can also offer loans and credit cards to customers as-needed.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="personal bank accounts" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Visit `}<a parentName="p" {...{
            "href": "/"
          }}>{`The Comparison Company`}</a>{` to learn more about the tips and tricks you can use to compare personal bank accounts today!`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      